import React, { useState } from "react"

import { Container, Figure } from "react-bootstrap"

import AgeCalculator from "../components/agegate-component"
// import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor"
// Star Backgrounds - Mobile
import StarsLeftTopM from "../images/stars-left-top-m.png"
import StarsLeftBottomM from "../images/stars-left-bottom-m.png"
import StarsRightTopM from "../images/stars-right-top-m.png"
import StarsRightBottomM from "../images/stars-right-bottom-m.png"

// Star Backgrounds - Desktop
import StarsLeft from "../images/stars-left.png"
import StarsRight from "../images/stars-right.png"
// configureAnchors({ offset: -20 })
const AgeGate = props => {
  const [isOldError, setIsOldError] = useState(false)
  const [ageError, setAgeError] = useState(false)
  return (
    <>
      {/* <ScrollableAnchor id={"main"}> */}
      <section className="main-content py-4 position-relative">
        <Container>
          <div className="text-white px-2">
            <h1 className="text-center text-uppercase heading heading-lg py-4 mb-0">
              Are You Over 18?
            </h1>
            <div className="text-center">
              <AgeCalculator
                setAgeGateShow={props.setAgeGateShow}
                setCountrySelection={props.setCountrySelection}
                setIsOldError={setIsOldError}
                setAgeError={setAgeError}
              />
            </div>

            {isOldError && (
              <h3
                className="text-center text-uppercase py-md-4 mb-0"
                style={{ color: "#ffd204" }}
              >
                {/* You are not Eligible to Enter into Site! */}
                Oops, please complete your details then press enter.
              </h3>
            )}
            {ageError && (
              <h3
                className="text-center text-uppercase py-md-4 mb-0"
                style={{ color: "#ffd204" }}
              >
                {/* You are not Eligible to Enter into Site! */}
                Sorry you aren't allowed to enter this site.
              </h3>
            )}
          </div>
        </Container>

        {/* Background Star Images - Mobile */}
        <div className="d-md-none">
          <Figure className="mb-0 stars-left-top-m position-absolute">
            <Figure.Image src={StarsLeftTopM} alt="Stars Left Top" />
          </Figure>
          <Figure className="mb-0 stars-left-bottom-m position-absolute">
            <Figure.Image src={StarsLeftBottomM} alt="Stars Left Bottom" />
          </Figure>
          <Figure className="mb-0 stars-right-top-m position-absolute">
            <Figure.Image src={StarsRightTopM} alt="Stars Right Top" />
          </Figure>
          <Figure className="mb-0 stars-right-bottom-m position-absolute">
            <Figure.Image src={StarsRightBottomM} alt="Stars Right Bottom" />
          </Figure>
        </div>

        {/* Background Star Images - Desktop */}
        <div className="d-none d-md-block">
          <Figure className="mb-0 stars-left position-absolute">
            <Figure.Image src={StarsLeft} alt="Stars Left" />
          </Figure>
          <Figure className="mb-0 stars-right position-absolute">
            <Figure.Image src={StarsRight} alt="Stars Right" />
          </Figure>
        </div>
      </section>
      {/* </ScrollableAnchor> */}
    </>
  )
}

export default AgeGate
