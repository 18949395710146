import React, { useEffect } from "react"
import Cookies from "universal-cookie"

import { Container, Row, Button, Figure } from "react-bootstrap"

// Star Backgrounds - Mobile
import StarsLeftTopM from "../images/stars-left-top-m.png"
import StarsLeftBottomM from "../images/stars-left-bottom-m.png"
import StarsRightTopM from "../images/stars-right-top-m.png"
import StarsRightBottomM from "../images/stars-right-bottom-m.png"

// Star Backgrounds - Desktop
import StarsLeft from "../images/stars-left.png"
import StarsRight from "../images/stars-right.png"

const CountrySelection = props => {
  // let isCountryUk = new Cookies()
  // let isCountryRoi = new Cookies()
  // let isRoiAnswered = new Cookies()

  let isCountrySelected = new Cookies()
  let ageCookie = new Cookies()
  useEffect(() => {
    // if (isCountryUk.get("isCountryUk") === "yes") {
    //   props.setCountrySelection(false)
    //   props.setAreYouWinner(true)
    // } else if (
    //   isCountryUk.get("isCountryUk") === "yes" &&
    //   isCountryRoi.get("isCountryRoi") === "no"
    // ) {
    //   props.setCountrySelection(false)
    //   props.setAreYouWinner(true)
    // } else if (
    //   isRoiAnswered.get("isRoiAnswered") === "no" &&
    //   isCountryRoi.get("isCountryRoi") === "yes"
    // ) {
    //   props.setCountrySelection(false)
    //   props.setRoiQuestion(true)
    // } else if (
    //   isRoiAnswered.get("isRoiAnswered") === "yes" &&
    //   isCountryRoi.get("isCountryRoi") === "yes"
    // ) {
    //   props.setRoiQuestion(false)
    //   props.setAreYouWinner(true)
    // } else {
    //   isCountryUk.set("isCountryUk", "no", { path: "/" })
    //   isCountryRoi.set("isCountryRoi", "no", { path: "/" })
    //   isRoiAnswered.set("isRoiAnswered", "no", { path: "/" })
    //   props.setCountrySelection(true)
    // }
    var country = isCountrySelected.get("isCountrySelected")
    if (isCountrySelected.get("isCountrySelected") === "GB") {
      props.setCountrySelection(false)
      props.setAreYouWinner({ state: true, countryCode: country })
    } else if (isCountrySelected.get("isCountrySelected") === "IE") {
      props.setCountrySelection(false)
      props.setRoiQuestion({ state: true, countryCode: country })
    } else if (isCountrySelected.get("isCountrySelected") === "IEAnswered") {
      props.setCountrySelection(false)
      props.setAreYouWinner({ state: true, countryCode: country })
    } else {
      isCountrySelected.set("isCountrySelected", "no", { path: "/" })
      props.setCountrySelection(true)
    }
  }, [])
  const ukhandler = () => {
    if (ageCookie.get("isOldEnough") === "yes") {
      isCountrySelected.set("isCountrySelected", "GB", {
        path: "/",
        maxAge: 2592000,
        // secure: true,
        // httpOnly: true,
        // sameSite: true,
      })
    }

    props.setCountrySelection(false)
    props.setAreYouWinner({ state: true, countryCode: "GB" })
  }
  const roihandler = () => {
    if (ageCookie.get("isOldEnough") === "yes") {
      isCountrySelected.set("isCountrySelected", "IE", {
        path: "/",
        maxAge: 2592000,
        // secure: true,
        // httpOnly: true,
        // sameSite: true,
      })
    }
    props.setCountrySelection(false)
    props.setRoiQuestion({ state: true, countryCode: "IE" })
  }
  return (
    <>
      <section className="main-content py-4 py-md-5 position-relative">
        <Container>
          <div className="text-white px-4">
            <h1 className="text-center text-uppercase heading mb-0">
              Are you entering from{" "}
              <span className="d-md-block">UK or Republic of Ireland?</span>
            </h1>
            <Row className="justify-content-center">
              <Button
                variant="danger"
                size="lg"
                className="rounded-pill text-uppercase mx-2 mt-4"
                style={{ width: "200px" }}
                onClick={roihandler}
              >
                Republic of Ireland
              </Button>
              <Button
                variant="dark"
                size="lg"
                className="rounded-pill text-uppercase mx-2 mt-4"
                style={{ width: "200px" }}
                onClick={ukhandler}
              >
                UK
              </Button>
            </Row>
          </div>
        </Container>

        {/* Background Star Images - Mobile */}
        <div className="d-md-none">
          <Figure className="mb-0 stars-left-top-m position-absolute">
            <Figure.Image src={StarsLeftTopM} alt="Stars Left Top" />
          </Figure>
          <Figure className="mb-0 stars-left-bottom-m position-absolute">
            <Figure.Image src={StarsLeftBottomM} alt="Stars Left Bottom" />
          </Figure>
          <Figure className="mb-0 stars-right-top-m position-absolute">
            <Figure.Image src={StarsRightTopM} alt="Stars Right Top" />
          </Figure>
          <Figure className="mb-0 stars-right-bottom-m position-absolute">
            <Figure.Image src={StarsRightBottomM} alt="Stars Right Bottom" />
          </Figure>
        </div>

        {/* Background Star Images - Desktop */}
        <div className="d-none d-md-block">
          <Figure className="mb-0 stars-left position-absolute">
            <Figure.Image src={StarsLeft} alt="Stars Left" />
          </Figure>
          <Figure className="mb-0 stars-right position-absolute">
            <Figure.Image src={StarsRight} alt="Stars Right" />
          </Figure>
        </div>
      </section>
    </>
  )
}

export default CountrySelection
