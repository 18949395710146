import React from "react"

import { Modal, Figure } from "react-bootstrap"

import barcodeImg from "../../images/barcode.png"

function BarcodeModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="align-items-start" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="text-center text-uppercase text-white mt-3 mb-0">
            You can find the last 4 digits{" "}
            <span className="d-lg-block">of the Barcode here</span>
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <Figure className="mb-4">
          <Figure.Image className="mb-0" src={barcodeImg} alt="Barcode" />
        </Figure>
      </Modal.Body>
    </Modal>
  )
}

export default BarcodeModal
