import React, { useState, useRef, useEffect } from "react"
import { useForm } from "react-hook-form"
// import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor"
import $ from "jquery"
// import ReCAPTCHA from "react-google-recaptcha"

// import axios from "axios"

import { Container, Row, Col, Figure, Form, Button } from "react-bootstrap"

import BarcodeModal from "./modals/barcode-modal"

// Star Backgrounds - Mobile
import HomeStarsLeftTopM from "../images/stars-left-top-m.png"
import HomeStarsLeftBottomM from "../images/stars-left-bottom-m.png"
import HomeStarsRightTopM from "../images/stars-right-top-m.png"
import HomeStarsRightBottomM from "../images/stars-right-bottom-m.png"
// Star Backgrounds - Desktop
import HomeStarsLeftTop from "../images/home-stars-left-top.png"
import HomeStarsLeftCenter from "../images/home-stars-left-center.png"
import HomeStarsLeftBottom from "../images/home-stars-left-bottom.png"
import HomeStarsRightTop from "../images/home-stars-right-top.png"
import HomeStarsRightBottom from "../images/home-stars-right-bottom.png"
// import WinnerDetailsSubmittedConfirmation from "./winner-details-submitted-confirmation"

// configureAnchors({ offset: -20 })

const AreYouWinner = props => {
  useEffect(() => {
    $(document).ready(function() {
      // Handler for .ready() called.
      $("html, body").animate(
        {
          scrollTop: $("#home").offset().top
        },
        "slow"
      )
    })
  }, [])
  const [barcodeModalShow, setBarcodeModalShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [captchaError, setCaptchaError] = useState(false)
  const [captchaValue, setCaptchaValue] = useState(false)
  const [captchaResponse, setCaptchaResponse] = useState("")
  // const recaptchaRef = useRef()
  let btnRef = useRef()
  var formData
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  // console.log(props.countryCode)
  const onSubmit = (data, event) => {
    event.preventDefault()
    // if (captchaValue === true) {
      // if (btnRef.current) {
      //   btnRef.current.setAttribute("disabled", "disabled")
      // }
      // setLoading(true)

      // Email Validation
      // let email = data.emailID
      // let emailCorrected
      // if (email.includes("@gmail.com") && email.includes("+")) {
      //   let emailWithPlus = email
      //   let emailWithPlusSplit = emailWithPlus.split("+")
      //   emailCorrected = emailWithPlusSplit[0] + "@gmail.com"
      // } else if (email.includes("@gmail.com") && email.includes(".")) {
      //   let emailWithDot = email
      //   let emailWithDotSplit = emailWithDot.split(".")
      //   let emailWithDotJoin = emailWithDotSplit.join("")
      //   let emailWithDotJoinSplit = emailWithDotJoin.split("@")
      //   let emailWithDotJoinSplitJoin = emailWithDotJoinSplit[0] + "@gmail.com"
      //   emailCorrected = emailWithDotJoinSplitJoin
      // } else {
      //   emailCorrected = email
      // }
      // // console.log(emailCorrected)
      // data.emailID = emailCorrected.toLowerCase()

      // var country = props.countryCode
      // if (country === "IEAnswered") country = "IE"
      // data.countryCode = country
      // data.DataPurpose = false
      // data.GlobalPurpose = false
      // data.packingCode = captchaResponse
      // if (data.BrandPurpose === true) {
      //   data.MpsOptin = true
      // } else {
      //   data.MpsOptin = false
      // }
      // formData = data
      // console.log(formData)

      // const username = "AuthenticatedAPIUser"
      // const password = "mrePi@KU_IOR_Cnmapiga"
      // const token = Buffer.from(`${username}:${password}`, "utf8").toString(
      //   "base64"
      // )
      // const postHeaders = {
      //   headers: {
      //     "Content-Type": "application/json; charset=utf-8",
      //     "Access-Control-Allow-Origin": "*"
      //     // Authorization: `Basic ${token}`,
      //     // "X-ApiKey": "eyGhjrtnkUyQxzplkjytgnmlqDavtUkaCbrQ",
      //   }
      // }
      // axios
      //   .post(
      //     //stage
      //     "https://stage.api.mms-streaming.co.uk/APMBAPI/GetWinner",
      //     //prod
      //     // "https://api.mms-streaming.co.uk/APMBAPI/GetWinner",
      //     formData,
      //     postHeaders
      //   )
      //   .then(response => {
      //     console.log(response.data)
      //     setLoading(false)
      //     if (response.data.intReturnParameters === 1) {
      //       if (response.data.PrizeCategory === "TV/Projector_Prize") {
              props.setAreYouWinner({
                state: false,
                countryCode: props.countryCode
              })
              props.setWinnerTVSoundbar({
                state: true,
                id: "1",
                // id: response.data.CustomerID,
                category: "TV/Projector_Prize"
                // category: response.data.PrizeCategory
              })
          //   } else if (response.data.PrizeCategory === "Amazon_Fire_Stick") {
          //     props.setAreYouWinner({
          //       state: false,
          //       countryCode: props.countryCode
          //     })
          //     props.setWinnerFireTVStick({
          //       state: true,
          //       id: response.data.CustomerID,
          //       category: response.data.PrizeCategory
          //     })
          //   } else if (response.data.PrizeCategory === "Free_Pouch") {
          //     props.setAreYouWinner({
          //       state: false,
          //       countryCode: props.countryCode
          //     })
          //     props.setWinnerFreePack({
          //       state: true,
          //       id: response.data.CustomerID,
          //       category: response.data.PrizeCategory
          //     })
          //   } else if (response.data.PrizeCategory === "M&M_Free_Bundle") {
          //     props.setAreYouWinner({
          //       state: false,
          //       countryCode: props.countryCode
          //     })
          //     props.setWinnerMmsBundle({
          //       state: true,
          //       id: response.data.CustomerID,
          //       category: response.data.PrizeCategory
          //     })
          //   }
          // } else {
          //   props.setAreYouWinner({
          //     state: false,
          //     countryCode: props.countryCode
          //   })
          //   props.setNotAWinner({
          //     state: true,
          //     message: response.data.strReturnMessage,
          //     countryCode: props.countryCode
          //   })
          // }
        // })
        // .catch(error => {
          // props.setAreYouWinner({
          //   state: false,
          //   countryCode: props.countryCode,
          // })
          // props.setNotAWinner({
          //   state: true,
          //   message: "",
          //   countryCode: props.countryCode,
          // })
          // setLoading(false)
          // setCaptchaError(true)
          // recaptchaRef.current.reset()
          // console.log(error)
    //     })
    // } else {
    //   setCaptchaError(true)
    // }
  }
  const onInvalid = e => {
    e.target.setCustomValidity("Please Enter a Valid Contact Number")
  }
  const onChange = value => {
    // console.log(value)
    if (value === null) {
      setCaptchaError(true)
      setCaptchaValue(false)
    } else {
      setCaptchaValue(true)
      setCaptchaError(false)
      setCaptchaResponse(value)
    }
  }
  const onExpiredCaptcha = () => {
    setCaptchaError(true)
    setCaptchaValue(false)
  }

  return (
    <>
      {loading && <div className="loading"></div>}
      {/* <ScrollableAnchor id={"main"}> */}
        <section className="main-content py-4 py-md-5 position-relative">
          <Container>
            <div className="text-white px-4">
              <h1
                className="text-center text-uppercase heading heading-lg mb-0"
                id="home"
              >
                Are you a Winner?
              </h1>

              <Form
                className="home mt-4 mt-md-5"
                onSubmit={handleSubmit(onSubmit)}
                //autoComplete="off"
              >
                <Row noGutters>
                  <Col lg={{ span: 8, offset: 2 }}>
                    <Form.Group controlId="barcode" className="mb-1">
                      <div>
                        <h5 className="mb-4 text-uppercase d-inline-block">
                          Please retain your promotional pack
                        </h5>
                      </div>
                      <Form.Control
                        type="text"
                        maxLength="4"
                        className="rounded-0"
                        placeholder="*Enter Last 4 Digits from Pack Barcode"
                        {...register("voucherCode", {
                          required: "Barcode is required",
                          minLength: {
                            value: 4,
                            message: "Please enter valid Barcode"
                          },
                          pattern: {
                            value: /^[0-9]+$/i,
                            message: "Please enter valid Barcode"
                          }
                        })}
                      />
                      {errors.voucherCode && (
                        <span className="input-error">
                          {errors.voucherCode.message}
                        </span>
                      )}
                    </Form.Group>
                    <div>
                      <h5
                        className="mb-4 text-uppercase d-inline-block"
                        onClick={() => setBarcodeModalShow(true)}
                        style={{ cursor: "pointer" }}
                      >
                        Where can I find my barcode?
                      </h5>
                    </div>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="firstName" className="mb-4">
                          <Form.Control
                            type="text"
                            maxLength="50"
                            className="rounded-0"
                            placeholder="*First Name"
                            {...register("firstName", { required: true })}
                          />
                          {errors.firstName && (
                            <span className="input-error">
                              First name is required
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="lastName" className="mb-4">
                          <Form.Control
                            type="text"
                            maxLength="50"
                            className="rounded-0"
                            placeholder="*Last Name"
                            {...register("lastName", { required: true })}
                          />
                          {errors.lastName && (
                            <span className="input-error">
                              Last name is required
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group controlId="email" className="mb-4">
                      <Form.Control
                        type="text"
                        maxLength="254"
                        className="rounded-0"
                        placeholder="*Email Address"
                        {...register("emailID", {
                          required: "Email ID is Required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid Email Address"
                          }
                        })}
                      />
                      {errors.emailID && (
                        <span className="input-error">
                          {errors.emailID.message}
                        </span>
                      )}
                    </Form.Group>
                    <Form.Group controlId="phoneNumber" className="mb-4">
                      {/* <Form.Label>Contact phone number *</Form.Label> */}
                      <Form.Control
                        // type="text"
                        maxLength="50"
                        className="rounded-0"
                        pattern="[0-9\-()]{1,50}"
                        onChange={onInvalid}
                        placeholder="*Contact phone number"
                        {...register("contactNumber", { required: true })}
                      />
                      {errors.contactNumber && (
                        <span className="input-error">
                          Contact phone number is required
                        </span>
                      )}
                    </Form.Group>
                    <Form.Group controlId="checkbox">
                      <Row>
                        <Col md={1} className="col-2">
                          <Form.Check
                            type="checkbox"
                            {...register("BrandPurpose")}
                          />
                        </Col>
                        <Col md={11} className="col-10 pl-0">
                          <label htmlFor="checkbox">
                            {/* <p className="text-uppercase mt-2">
                              By signing up, you are providing us consent to use
                              the information you provide to send the updates
                              you requested, such as newsletters, promos, deals,
                              and news. You may also request to access your data
                              at any time, and can opt out at any time.
                            </p> */}
                            <p className="text-uppercase">
                              I would like to receive personalised marketing
                              offers from M&M’s and other Mars Wrigley’s brands
                              via email phone and other electronic channels. You
                              can with-draw this consent at any time.
                            </p>
                          </label>
                        </Col>
                      </Row>
                    </Form.Group>
                    {/* <div className="mt-4">
                      <ReCAPTCHA
                        // Stage
                        sitekey="6LdCyDsbAAAAAJ-10hdF3UvVMuBjaB8kMUyPxf0l"
                        // Production
                        // sitekey="6LfKyjsbAAAAAF8a9eNXTwpPcVCgatRt50RZ0T7w"
                        onChange={onChange}
                        onExpired={onExpiredCaptcha}
                        ref={recaptchaRef}
                      />
                      {captchaError && (
                        <span className="input-error">
                          Please Accept Captcha
                        </span>
                      )}
                    </div> */}
                    <div className="text-center">
                      <Button
                        ref={btnRef}
                        variant="dark"
                        size="lg"
                        className="rounded-pill text-uppercase mt-4"
                        type="submit"
                      >
                        Enter
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
              {/* <div className="mt-4">
                <Recaptcha
                  ref={recaptcha}
                  // sitekey={"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}
                  sitekey={"6Ld78CQbAAAAAIXmcafQ1AvvRnAHVw0aG7k9ExzL"}
                  onResolved={onResolved}
                />
              </div> */}
            </div>
          </Container>

          {/* Background Star Images - Mobile */}
          <div className="d-md-none">
            <Figure className="mb-0 stars-left-top-m position-absolute">
              <Figure.Image src={HomeStarsLeftTopM} alt="Stars Left Top" />
            </Figure>
            <Figure className="mb-0 stars-left-center-m position-absolute">
              <Figure.Image
                src={HomeStarsLeftBottomM}
                alt="Stars Left Center"
              />
            </Figure>
            <Figure className="mb-0 stars-left-bottom-m position-absolute">
              <Figure.Image
                src={HomeStarsLeftBottomM}
                alt="Stars Left Bottom"
              />
            </Figure>
            <Figure className="mb-0 stars-right-top-m position-absolute">
              <Figure.Image src={HomeStarsRightTopM} alt="Stars Right Top" />
            </Figure>
            <Figure className="mb-0 stars-right-center-m position-absolute">
              <Figure.Image
                src={HomeStarsRightBottomM}
                alt="Stars Right Center"
              />
            </Figure>
            <Figure className="mb-0 stars-right-bottom-m position-absolute">
              <Figure.Image
                src={HomeStarsRightBottomM}
                alt="Stars Right Bottom"
              />
            </Figure>
          </div>

          {/* Background Star Images - Desktop */}
          <div className="d-none d-md-block">
            <Figure className="mb-0 home-stars-left-top position-absolute">
              <Figure.Image src={HomeStarsLeftTop} alt="Stars Left Top" />
            </Figure>
            <Figure className="mb-0 home-stars-left-center position-absolute">
              <Figure.Image src={HomeStarsLeftCenter} alt="Stars Left Center" />
            </Figure>
            <Figure className="mb-0 home-stars-left-bottom position-absolute">
              <Figure.Image src={HomeStarsLeftBottom} alt="Stars Left Bottom" />
            </Figure>
            <Figure className="mb-0 home-stars-right-top position-absolute">
              <Figure.Image src={HomeStarsRightTop} alt="Stars Right Top" />
            </Figure>
            <Figure className="mb-0 home-stars-right-bottom position-absolute">
              <Figure.Image
                src={HomeStarsRightBottom}
                alt="Stars Right Bottom"
              />
            </Figure>
          </div>
        </section>
      {/* </ScrollableAnchor> */}

      <BarcodeModal
        show={barcodeModalShow}
        onHide={() => setBarcodeModalShow(false)}
      />
    </>
  )
}

export default AreYouWinner
