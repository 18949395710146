import React from "react"

// import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor"

import { Container } from "react-bootstrap"

// Star Backgrounds
// import formStarsLeftTop from "../images/form-stars-left-top.png"
// import formStarsLeftCenter from "../images/form-stars-left-center.png"
// import formStarsRightTop from "../images/form-stars-right-top.png"
// import formStarsRightCenter from "../images/form-stars-right-center.png"

// configureAnchors({ offset: -20 })

const WinnerDetailsSubmittedConfirmation = () => {
  return (
    <>
      {/* <ScrollableAnchor id={"main"}> */}
        <section className="main-content py-4 position-relative">
          <Container>
            <div className="text-white px-2">
              <h1 className="text-center text-uppercase heading heading-lg mb-0">
                Winner!
              </h1>
              <h2 className="text-center text-uppercase mt-4">All Done!</h2>
              <h3 className="text-center text-uppercase mb-3 px-xl-5">
                Thanks for your details, we've sent you an email confirmation
                and our team will get going on processing the dispatch of your
                prize.
              </h3>
            </div>
          </Container>

          {/* <Figure className="mb-0 form-stars-left-top-top position-absolute">
            <Figure.Image src={formStarsLeftTop} alt="Stars Left Top" />
          </Figure>
          <Figure className="mb-0 form-stars-left-center position-absolute">
            <Figure.Image src={formStarsLeftCenter} alt="Stars Left Center" />
          </Figure>
          <Figure className="mb-0 form-stars-right-top position-absolute">
            <Figure.Image src={formStarsRightTop} alt="Stars Right Top" />
          </Figure>
          <Figure className="mb-0 form-stars-right-center position-absolute">
            <Figure.Image src={formStarsRightCenter} alt="Stars Right Center" />
          </Figure> */}
        </section>
      {/* </ScrollableAnchor> */}
    </>
  )
}

export default WinnerDetailsSubmittedConfirmation
